/* You can add global styles to this file, and also import other style files */
@import "@angular/material/prebuilt-themes/indigo-pink.css";

.inactive{
	background: indianred;
}

.warning{
	background: orange !important;
}

.danger{
	background: indianred !important;
}


.mat-status-done{
	color: green !important;
}

.mat-status-open{
	color: orange !important;
}
.mat-status-danger{
	color: indianred !important;
}